import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  checked?: boolean
  error?: boolean
  name: string
  label?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Checkbox = memo(
  forwardRef<HTMLInputElement, Props>(function Checkbox(
    {
      checked,
      error = false,
      name,
      label,
      required,
      value,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    const id = `#form-${name}`

    return (
      <Container dial={5} inline row>
        <InputField
          ref={ref}
          checked={checked}
          id={id}
          name={name}
          type="checkbox"
          required={required}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        {label ? (
          <Label
            error={error}
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : null}
      </Container>
    )
  }),
)
Checkbox.displayName = 'Checkbox'

const Container = styled(FlexBox)`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.6875rem;
  margin-top: 1.5rem;
  position: relative;
  text-align: left;
`

const InputField = styled.input`
  opacity: 0;
  position: absolute;
  visibility: hidden;
  z-index: -1;

  &:checked ~ label {
    &:before {
      border-color: ${({ theme }) => theme.colors.variants.primaryDark};
    }
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const Label = styled.label<ContainerProps>`
  ${({ theme, error }) => {
    return css`
      color: ${theme.colors.variants.neutralDark3};
      padding-left: 1.125rem;
      &:before,
      &:after {
        content: '';
        border-radius: 50%;
        position: absolute;
        transition: 0.3s cubic-bezier(0, 0.55, 0.45, 1);
      }
      &:before {
        width: 0.75rem;
        height: 0.75rem;
        border: 0.0625rem solid
          ${error
            ? theme.colors.variants.danger
            : theme.colors.variants.primaryDark};
        top: 0.4375rem;
        left: 0;
      }
      &:after {
        width: 0.5rem;
        height: 0.5rem;
        background: ${theme.colors.variants.primaryDark};
        opacity: 0;
        top: 0.5625rem;
        left: 0.125rem;
      }

      a {
        font-weight: 700;
        text-decoration: underline;

        @media (max-width: 767px) {
          pointer-events: none;
        }
      }
    `
  }}
`

interface ContainerProps {
  error: boolean
}
