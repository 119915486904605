import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  ContactsFormSenderBackend,
  FormData,
} from 'app/utils/ContactsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  description?: string
  label?: string
  languageCode: string
  languagePrefix?: string | null
  onContactsFormSendToBackend: ContactsFormSenderBackend
  title?: string
}

export const ContactsForm = memo(function ContactsForm({
  description,
  label,
  languageCode,
  languagePrefix,
  onContactsFormSendToBackend,
  title,
}: Props) {
  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onContactsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Contacts Form',
          })
      }
    },
    [isSendingForm, onContactsFormSendToBackend],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container row tag="section" stretch wrap>
      <LeftSide>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </LeftSide>

      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} variant="form" />

        <Wrapper row wrap space="between">
          <Field>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              placeholder={`${useVocabularyData('name', languageCode)}*`}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.lastname !== undefined}
              isDirty={formState.dirtyFields.lastname}
              placeholder={`${useVocabularyData('lastname', languageCode)}*`}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.email !== undefined}
              isDirty={formState.dirtyFields.email}
              placeholder={`${useVocabularyData('email', languageCode)}*`}
              type="email"
              required={true}
              {...register('email', {
                required: true,
                validate: (email) => EMAIL_REGEX.test(email),
              })}
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.phone !== undefined}
              isDirty={formState.dirtyFields.phone}
              placeholder={`${useVocabularyData('phone', languageCode)}*`}
              type="text"
              {...register('phone', {
                required: true,
                validate: (phone) => phone.length > 2,
              })}
            />
          </Field>

          <Field className="large-field">
            <Textarea
              placeholder={useVocabularyData('message', languageCode)}
              required={false}
              {...register('message')}
            />
          </Field>

          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('form-policy-text', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
        </Wrapper>

        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}

        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData('form-confirmation-message', languageCode)}
            title={useVocabularyData(
              'form-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}

        <Input
          name="submit"
          type="submit"
          value={useVocabularyData('send-request', languageCode)}
          variant="submit"
        />
      </Form>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 25%;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  padding: 3.75rem 4.625rem 3.75rem 3.75rem;

  @media (max-width: 1199px) {
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    color: ${({ theme }) => theme.colors.variants.neutralLight5};
    padding: 2.5rem 1.875rem;
    text-align: center;
  }
`

const Label = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 0.875rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  max-width: 9.375rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;
  margin-top: 1.25rem;
`

const Form = styled.form`
  width: 75%;
  padding: 4.875rem 10vw 7.5rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 1.875rem 1.875rem 2.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  text-align: left;
`

const Field = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 2rem;
  &.large-field {
    width: 100%;
    margin-top: 3rem;
  }

  @media (max-width: 1023px) {
    width: calc(50% - 0.75rem);
  }

  @media (max-width: 424px) {
    width: 100%;
    &.large-field {
      margin-top: 2.5rem;
    }
  }
`
